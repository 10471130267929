import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateNavigationParam, updatePartnerUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import Storage from '../../utils/storage';
import {Link} from "react-router-dom";
import API from "../../app/api";
import {withCookies} from "react-cookie";

import './login.css';
import Button from "../../app/components/ui/button/button";
import {ReactComponent as GoToNaPartnerIcon} from "../../images/goToNaPartner.svg";
import {ReactComponent as LogoIcon} from "../../images/logo.svg";
import TextField from "../../app/components/ui/textField/textField";

class LoginPartners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            errorText: null
        };
    }

    authorize = (callback) => {
        this.setState({errorText: null});

        let {email, password} = this.state;
        API.csrf().then(data => {
            Storage.set('csrf', data.csrf_token);

            API.loginPartner(email, password)
                .then(data => {
                    const jwtToken = data.token;
                    this.props.cookies.set('token', jwtToken, {path: '/'});
                    Storage.set('token', jwtToken);
                    callback(true);
                    API.profilePartner()
                        .then(data => {
                            Storage.set('user', data);
                            data.authorize = true;
                            this.props.updatePartnerUser(data);
                        })
                        .catch(() => {
                            this.props.updatePartnerUser({authorize: false});
                        });
                })
                .catch(() => {
                    this.setState({errorText: 'Неверный email или пароль'});
                    callback(false);
                });
        }).catch(console.error);
    };

    logout = () => {
        this.props.cookies.remove('token', {path: '/'});
        this.props.updatePartnerUser({authorize: false});
    };

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'login',
        })

        Utils.setPageTitle(`Авторизация | ПабХаб`)
    }

    render() {
        const partnerUser = Storage.get('user');
        const isAuthorized = this.props.partnerUser.authorize;

        return (
            <div className='auth-container'>
                <div className='logo'>
                    <LogoIcon/>
                    <div className='for-business'>
                        для бизнеса
                    </div>
                </div>
                <div className='auth'>
                    <div className='auth-form'>
                        <Button customClass='switch-button' type='secondary' size='large' endIcon={<GoToNaPartnerIcon/>}
                                href='/login/'
                                fullWidth>
                            Ко входу на портал
                        </Button>

                        <div className='card'>
                            <div className='header'>
                                <div className='label'>Вход для партнёров</div>
                            </div>
                            {isAuthorized ? (
                                <>
                                    <div className='authorize'>
                                        <div className='label'>Вы вошли как</div>
                                        <div className='email'>{partnerUser.email}</div>
                                    </div>
                                    <a className='button' href={partnerUser.redirect_url}>Продолжить</a>
                                </>
                            ) : (
                                <>
                                    <div className='inputs'>
                                        <TextField
                                            label='Почта'
                                            type='email'
                                            value={this.state.email} fullWidth
                                            onChange={(e) => this.setState({email: e.target.value})}
                                        />
                                        <TextField
                                            label='Пароль'
                                            type='password'
                                            value={this.state.password} fullWidth
                                            onChange={(e) => this.setState({password: e.target.value})}
                                        />
                                    </div>
                                    {this.state.errorText && <div className='error'>{this.state.errorText}</div>}
                                    <div className='button' onClick={() => this.authorize(() => {
                                    })}>Войти
                                    </div>
                                </>
                            )}
                        </div>
                        <div className='extra'>
                            {
                                isAuthorized ?
                                    <div className='register link' onClick={this.logout}>Выйти из аккаунта</div> :
                                    <>
                                        <Link className='register link' to='/register/partners/'>Регистрация</Link>
                                        <Link className='forgot link' to='/forgot/?type=partners'>Забыли пароль?</Link>
                                    </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToPropsPartners = (state) => ({
    partnerUser: state.system.partnerUser
});

const mapDispatchToPropsPartners = (dispatch) => bindActionCreators({
    updatePartnerUser,
    updateNavigationParam
}, dispatch);

export default connect(mapStateToPropsPartners, mapDispatchToPropsPartners)(withRouterHOC(withCookies(LoginPartners)));
