import React, { Component } from 'react';
import './textField.css';

class TextField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
        };

        this.inputRef = React.createRef();
        this.containerRef = React.createRef();

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleContainerClick = this.handleContainerClick.bind(this);
    }

    handleFocus() {
        this.setState({ isFocused: true });
    }

    handleBlur() {
        const { value } = this.props;
        this.setState({ isFocused: value !== '' });
    }

    handleContainerClick() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    preventScroll = (event) => {
        if (this.inputRef.current && this.inputRef.current.type === 'number') {
            event.preventDefault();
        }
    };

    componentDidMount() {
        if (this.inputRef.current && this.inputRef.current.type === 'number') {
            this.inputRef.current.addEventListener('wheel', this.preventScroll);
        }
    }

    componentWillUnmount() {
        if (this.inputRef.current && this.inputRef.current.type === 'number') {
            this.inputRef.current.removeEventListener('wheel', this.preventScroll);
        }
    }

    render() {
        const {
            customClass,
            label,
            value,
            onChange,
            placeholder = '',
            fullWidth,
            type = 'text',
            multiline = false,
            ...props
        } = this.props;

        return (
            <div
                ref={this.containerRef}
                className={`text-field-component ${document.activeElement === this.inputRef.current ? 'focused' : ''} ${value ? 'filled' : ''} ${
                    customClass ? customClass : ''
                } ${fullWidth ? 'full-width' : ''}`}
                onClick={this.handleContainerClick}
            >
                {multiline ? (
                    <textarea
                        ref={this.inputRef}
                        value={value !== null ? value : ''}
                        onChange={onChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        placeholder={placeholder}
                        {...props}
                    />
                ) : (
                    <input
                        type={type}
                        ref={this.inputRef}
                        value={value !== null ? value : ''}
                        onChange={onChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        placeholder={placeholder}
                        {...props}
                    />
                )}
                <label>{label}</label>
            </div>
        );
    }
}

export default TextField;
