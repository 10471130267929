import {get, post, url} from '../utils/request';

class API {
    static csrf() {
        return get(url('/api/csrf/'));
    }

    static sendBusinessLandingRequest(name, contact) {
        const data = {
            name: name,
            contact: contact,
        };
        return post(url('/api/landing/business/request/'), data)
    }

    static mainPage() {
        return get(url(`/api/portal/main/`));
    }

    static categories(withCollections) {
        return get(url('/api/portal/categories/', {withCollections: withCollections === true}));
    }

    static category(slug) {
        return get(url(`/api/portal/category/${slug}/`));
    }

    static categoryItems(slug, page) {
        return get(url(`/api/portal/category/${slug}/items/`, {page: page}));
    }

    static categoryEntity(slug, categorySlug) {
        return get(url(`/api/portal/category/${slug}/${categorySlug}/`));
    }

    static shopEntity(shopSlug) {
        return get(url(`/api/portal/shop/${shopSlug}/`));
    }

    static shopEntityProducts(shopSlug) {
        return get(url(`/api/portal/shop/${shopSlug}/products/`));
    }

    static categoryEntityProducts(slug, categorySlug, page) {
        return get(url(`/api/portal/category/${slug}/${categorySlug}/products/`, {page: page}));
    }

    static productEntity(productSlug) {
        return get(url(`/api/portal/product/${productSlug}/`));
    }

    static login(email, password) {
        const data = {
            email: email,
            password: password,
        };
        return post(url('/api/portal/auth/login/'), data);
    }

    static register(email, password, firstName, username) {
        const data = {
            email: email,
            password: password,
            first_name: firstName,
            username: username
        }
        return post(url('/api/portal/auth/register/'), data)
    }

    static resetPasswordStart(email, accountType) {
        const data = {
            email: email,
            account_type: accountType
        }
        return post(url('/api/portal/auth/reset/password/'), data)
    }

    static resetPasswordEnd(token, newPassword) {
        const data = {
            token: token,
            new_password: newPassword
        }
        return post(url('/api/portal/auth/reset/password/code/'), data)
    }

    static loginPartner(email, password) {
        const data = {
            email: email,
            password: password,
        };
        return post(url('/api/auth/login/'), data);
    }

    static registerPartners( email, password, firstName, lastName) {
        const data = {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName
        }
        return post(url('/api/auth/register/'), data)
    }

    static registerPartnersCreateShop(address, name, website) {
        const data = {
            address: address,
            name: name,
            website: website,
        }
        return post(url('/api/auth/register/shop/create/'), data)
    }

    static profile() {
        return get(url('/api/portal/profile/'));
    }

    static profilePartner() {
        return get(url('/api/profile/'));
    }

    static profileEdit(name, avatar) {
        const data = {
            avatar: avatar,
            name: name
        };
        return post(url('/api/portal/profile/edit/'), data)
    }

    static profileChangePassword(oldPassword, newPassword) {
        const data = {
            old_password: oldPassword,
            new_password: newPassword
        };
        return post(url('/api/portal/profile/change/password/'), data)
    }

    static userProfile(slug) {
        return get(url(`/api/portal/user/profile/${slug}/`))
    }

    static userProfileReviews(slug, page) {
         return get(url(`/api/portal/user/profile/${slug}/reviews/`, {page: page}));
    }

    static addReview(productId, comment, image, rating) {
        const data = {
            product_id: productId,
            comment: comment,
            image: image,
            rating: rating
        };
        return post(url('/api/portal/review/add/'), data)
    }

    static search(searchQuery, page) {
        return get(url('/api/portal/search/', {search_query: searchQuery, page: page}))
    }
}

export default API;
