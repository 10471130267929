import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCollection, updateNavigationParam, updatePartnerUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import {withCookies} from 'react-cookie';
import Storage from '../../utils/storage';
import {Link, redirect} from "react-router-dom";

import './login.css';
import API from "../../app/api";
import {ReactComponent as LogoIcon} from "../../images/logo.svg";
import TextField from "../../app/components/ui/textField/textField";

class RegisterPartners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            password: null,
            firstName: null,
            lastName: null,

            activeTab: 'portal',
            tabs: {
                portal: 'на портал',
                partners: 'партнерам'
            },

            errorText: null,

            step: 1,
        }

        this.register = () => {
            this.setState({errorText: null})
            API.registerPartners(this.state.email, this.state.password, this.state.firstName, this.state.lastName)
                .then(data => {
                    if (data.status) {
                        const jwtToken = data.token;
                        this.props.cookies.set('token', jwtToken, {path: '/'})
                        Storage.set('token', jwtToken)

                        API.profilePartner()
                            .then(data => {
                                Storage.set('user', data)
                                data.authorize = true
                                this.props.updatePartnerUser(data)
                                window.location.href = '/register/partners/shop/create/'
                            })
                            .catch(e => {
                                this.props.updatePartnerUser({authorize: false})
                            })

                    } else {
                        this.setState({errorText: data.message})
                        this.props.updateUser({authorize: false})
                    }
                })
        }
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'register',
        })

        Utils.setPageTitle(`Регистрация | ПабХаб`)
    }

    render() {
        return (
            <div className='auth-container'>
                <div className='logo'>
                    <LogoIcon/>
                    <div className='for-business'>
                        для бизнеса
                    </div>
                </div>
                <div className='auth'>
                    <div className='auth-form'>
                        <div className='card'>
                            <div className='header'>
                                <div className='label'>Регистрация партнёра</div>
                            </div>
                            <div className='inputs'>
                                <div className='input'>
                                    <TextField label='Имя' type='text' name='first_name'
                                               autoComplete="new-password"
                                               onChange={(event) => this.setState({firstName: event.target.value})}
                                    />
                                </div>
                                <div className='input'>
                                    <TextField label='Фамилия' type='text' name='last_name' autoComplete="new-password"
                                               onChange={(event) => this.setState({lastName: event.target.value})}
                                    />
                                </div>
                            </div>
                            <div className='inputs'>
                                <div className='input'>
                                    <TextField label='Email' name='email' type='email' autoComplete="new-password"
                                               onChange={(event) => this.setState({email: event.target.value})}
                                    />
                                </div>
                                <div className='input'>
                                    <TextField label='Пароль' name='password' type='password'
                                               autoComplete="new-password"
                                               onChange={(event) => this.setState({password: event.target.value})}
                                    />
                                </div>
                            </div>
                            {
                                this.state.errorText ? <div className='error'>{this.state.errorText}</div> : null
                            }
                            <div className='button'
                                 onClick={() => this.register()}>
                                Создать аккаунт
                            </div>
                        </div>
                        <div className='extra'>
                            <Link className='register link' to='/login/partners/'>Войти как партнер</Link>
                            <Link className='forgot link' to='/forgot?type=partners'>Забыли пароль?</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    partnerUser: state.system.partnerUser,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updatePartnerUser: updatePartnerUser,
            updateCollection: updateCollection,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouterHOC(RegisterPartners)));