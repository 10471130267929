import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import Storage from '../../utils/storage';
import {Link} from "react-router-dom";
import API from "../../app/api";
import {withCookies} from "react-cookie";


import {ReactComponent as LogoIcon} from "../../images/logo.svg";
import {ReactComponent as GoToNaPartnerIcon} from "../../images/goToNaPartner.svg";


import './login.css';
import TextField from "../../app/components/ui/textField/textField";
import Button from "../../app/components/ui/button/button";

class LoginPortal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            errorText: null,

            activeTab: 'portal',
            tabs: {
                portal: 'на портал',
                partners: 'партнерам'
            },

            tokenPortal: null,
            tokenPartners: null,
        };
    }

    authorize = (callback) => {
        this.setState({errorText: null});

        let {email, password} = this.state;
        API.csrf().then(data => {
            Storage.set('csrf', data.csrf_token);

            API.login(email, password)
                .then(data => {
                    const jwtToken = data.token;
                    this.props.cookies.set('portalToken', jwtToken, {path: '/'});
                    Storage.set('portalToken', jwtToken);
                    callback(true);
                    API.profile()
                        .then(data => {
                            Storage.set('portalUser', data);
                            data.authorize = true;
                            this.props.updateUser(data);
                        })
                        .catch(() => {
                            this.props.updateUser({authorize: false});
                        });
                })
                .catch(() => {
                    this.setState({errorText: 'Неверный email или пароль'});
                    callback(false);
                });
        }).catch(console.error);
    };

    logout = () => {
        this.props.cookies.remove('portalToken', {path: '/'});
        this.props.updateUser({authorize: false});
    };

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'login',
        })

        Utils.setPageTitle(`Авторизация | ПабХаб`)
    }

    render() {
        const portalUser = Storage.get('portalUser');
        const isAuthorized = this.props.user.authorize;

        return (
            <div className='auth-container'>
                <div className='logo'>
                    <LogoIcon/>
                </div>
                <div className='auth'>
                    <div className='auth-form'>
                        <Button customClass='switch-button' type='secondary' size='large' endIcon={<GoToNaPartnerIcon/>}
                                href='/login/partners/'
                                fullWidth>
                            Ко входу для партнёров
                        </Button>

                        <div className='card'>
                            <div className='header'>
                                <div className='label'>Вход на портал</div>
                            </div>
                            {isAuthorized ? (
                                <>
                                    <div className='authorize'>
                                        <div className='label'>Вы вошли как</div>
                                        <div className='email'>{portalUser.email}</div>
                                    </div>
                                    <Link className='button' to={`/user/${portalUser.username}`}>Продолжить</Link>
                                    <div className='register link' onClick={this.logout}>Выйти из аккаунта</div>
                                </>
                            ) : (
                                <>
                                    <div className='inputs'>
                                        <TextField
                                            label='Почта'
                                            type='email'
                                            value={this.state.email} fullWidth
                                            onChange={(e) => this.setState({email: e.target.value})}
                                        />
                                        <TextField
                                            label='Пароль'
                                            type='password'
                                            value={this.state.password} fullWidth
                                            onChange={(e) => this.setState({password: e.target.value})}
                                        />
                                    </div>
                                    {this.state.errorText && <div className='error'>{this.state.errorText}</div>}
                                    <div className='button' onClick={() => this.authorize(() => {
                                    })}>Войти
                                    </div>
                                </>
                            )}
                        </div>
                        <div className='extra'>
                            <Link className='register link' to='/register'>Регистрация</Link>
                            <Link className='forgot link' to='/forgot'>Забыли пароль?</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToPropsPortal = (state) => ({
    user: state.system.user
});

const mapDispatchToPropsPortal = (dispatch) => bindActionCreators({
    updateUser,
    updateNavigationParam
}, dispatch);

export default connect(mapStateToPropsPortal, mapDispatchToPropsPortal)(withRouterHOC(withCookies(LoginPortal)));

