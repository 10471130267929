import React, {Component} from 'react';
import PropTypes from "prop-types";

import './button.css';

class Button extends Component {
    constructor(props) {
        super(props);

        this.onClick = (e) => {
            if (this.props.disabled) return;

            if (this.props.href && this.props.blank) {
                window.open(this.props.href, '_blank');
            } else if (this.props.href) {
                window.location.href = this.props.href
            }
            try {
                this.props.onClick(e);
            } catch (e) {
                console.error(e);
            }
        };
    }

    render() {
        const {type, size, hint, startIcon, endIcon, disabled, customClass, style, fullWidth, children} = this.props;

        const isIconButton = (!children && !hint && (startIcon || endIcon));

        return (
            <div
                style={style}
                className={`button-component ${type} ${size} ${isIconButton ? 'icon' : ''} ${disabled ? 'disabled' : ''} ${fullWidth ? 'full-width' : ''} ${customClass}`}
                onClick={this.onClick}
            >
                {startIcon ? <div className='icon start-icon'>{startIcon}</div> : null}
                <div className='entry'>
                    {children && <div className='text'>{children}</div>}
                    {hint && <div className='hint'>{hint}</div>}
                </div>
                {endIcon ? <div className='icon end-icon'>{endIcon}</div> : null}
            </div>
        );
    }
}

Button.propTypes = {
    customClass: PropTypes.string,
    href: PropTypes.string,
    fullWidth: PropTypes.bool,
    blank: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.oneOf(['accent', 'transparent', 'outlined', 'secondary', 'primary', 'text']),
    size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large', 'x-large', '']),
    hint: PropTypes.string,
    onClick: PropTypes.func,
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    disabled: PropTypes.bool,
    children: PropTypes.node
};

Button.defaultProps = {
    customClass: '',
    style: null,
    type: 'primary',
    size: 'medium',
    onClick: () => {
    },
    startIcon: null,
    endIcon: null,
    hint: '',
    disabled: false,
    children: null
};

export default Button;