import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCollection, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import {withCookies} from 'react-cookie';
import Storage from '../../utils/storage';
import {Link} from "react-router-dom";

import './login.css';
import API from "../../app/api";
import {ReactComponent as LogoIcon} from "../../images/logo.svg";
import TextField from "../../app/components/ui/textField/textField";

class RegisterPartnersCreateShop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: null,
            name: null,
            website: null,

            activeTab: 'portal',
            tabs: {
                portal: 'на портал',
                partners: 'партнерам'
            },

            errorText: null,

            step: 1,
        }

        this.register = () => {
            this.setState({errorText: null})
            API.registerPartnersCreateShop(this.state.address, this.state.name, this.state.website)
                .then(data => {
                    window.location.href = '/cabinet/'
                })
        }
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'register',
        })

        Utils.setPageTitle(`Регистрация | ПабХаб`)
    }

    render() {
        return (
            <div className='auth-container'>
                <div className='logo'>
                    <LogoIcon/>
                    <div className='for-business'>
                        для бизнеса
                    </div>
                </div>
                <div className='auth'>
                    <div className='auth-form'>
                        <div className='card'>
                            <div className='header'>
                                <div className='label'>Создайте свой магазин</div>
                                <div className='description'>
                                    Почту подтвердили, осталось только создать магазин, к которому будут прикрепляться
                                    позиции
                                </div>
                            </div>
                            <div className='inputs'>
                                <div className='input'>
                                    <TextField label='Адрес без города' type='text' name='address'
                                               autoComplete="new-password"
                                               onChange={(event) => this.setState({address: event.target.value})}
                                    />
                                </div>
                                <div className='input'>
                                    <TextField label='Название точки или сети' type='text' name='name'
                                               autoComplete="new-password"
                                               onChange={(event) => this.setState({name: event.target.value})}
                                    />
                                </div>
                            </div>
                            <div className='inputs'>
                                <div className='input'>
                                    <TextField label='Сайт или телеграм-канал, если есть' name='website' type='website'
                                               autoComplete="new-password"
                                               onChange={(event) => this.setState({website: event.target.value})}
                                    />
                                </div>
                            </div>
                            {
                                this.state.errorText ? <div className='error'>{this.state.errorText}</div> : null
                            }
                            <div className='button'
                                 onClick={() => this.register()}>
                                Создать магазин
                            </div>
                        </div>
                        <div className='extra'>
                            <Link className='register link' to='/login/partners/'>Войти как партнер</Link>
                            <Link className='forgot link' to='/forgot?type=partners'>Забыли пароль?</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    partnerUser: state.system.partnerUser,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateCollection: updateCollection,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouterHOC(RegisterPartnersCreateShop)));