import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCollection, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";
import Utils, {withRouterHOC} from "../../utils";
import {withCookies} from 'react-cookie';
import Storage from '../../utils/storage';
import {Link, redirect} from "react-router-dom";

import './login.css';
import API from "../../app/api";

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            password: null,
            firstName: null,
            username: null,

            activeTab: 'portal',
            tabs: {
                portal: 'на портал',
                partners: 'партнерам'
            },

            errorText: null
        }

        this.register = () => {
            this.setState({errorText: null})
            API.register(this.state.email, this.state.password, this.state.firstName, this.state.username)
                .then(data => {
                    if (data.status) {
                        const jwtToken = data.token;
                        this.props.cookies.set('portalToken', jwtToken, {path: '/'})
                        Storage.set('portalToken', jwtToken)

                        API.profile()
                            .then(data => {
                                Storage.set('portalUser', data)
                                data.authorize = true
                                this.props.updateUser(data)
                                window.location.href = `/user/${data.username}/`
                            })
                            .catch(e => {
                                this.props.updateUser({authorize: false})
                            })

                    } else {
                        this.setState({errorText: data.message})
                        this.props.updateUser({authorize: false})
                    }
                })
        }
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'register',
        })

        Utils.setPageTitle(`Регистрация | ПабХаб`)
    }

    render() {
        return (
            <div className='auth-container'>
                <div className='auth'>
                    <div className='auth-form'>
                        <div className='card'>
                            <div className='header'>
                                <div className='label'>Регистрация на портале</div>
                            </div>
                            <div className='inputs'>
                                <div className='input'>
                                    <input placeholder='Как вас зовут?' type='text' name='first_name'
                                           autoComplete="new-password"
                                           onChange={(event) => this.setState({firstName: event.target.value})}
                                    />
                                </div>
                                <div className='input'>
                                    <input placeholder='Логин' type='text' name='username' autoComplete="new-password"
                                           onChange={(event) => this.setState({username: event.target.value})}
                                    />
                                </div>
                            </div>
                            <div className='inputs'>
                                <div className='input'>
                                    <input placeholder='Email' name='email' type='email' autoComplete="new-password"
                                           onChange={(event) => this.setState({email: event.target.value})}
                                    />
                                </div>
                                <div className='input'>
                                    <input placeholder='Пароль' name='password' type='password'
                                           autoComplete="new-password"
                                           onChange={(event) => this.setState({password: event.target.value})}
                                    />
                                </div>
                            </div>
                            {
                                this.state.errorText ? <div className='error'>{this.state.errorText}</div> : null
                            }
                            <div className='button'
                                 onClick={() => this.register()}>
                                Регистрация
                            </div>
                        </div>
                        <div className='extra'>
                            <Link className='register link' to='/login'>Войти</Link>
                            <Link className='forgot link' to='/forgot'>Забыли пароль?</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    partnerUser: state.system.partnerUser,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateCollection: updateCollection,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouterHOC(Register)));